import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonScroll } from "../Buttons"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const About = () => {
  return (
    <section
      id="nexpa"
      className="max-w-7xl lg:mx-auto px-8 py-8 lg:py-0 flex flex-col lg:grid lg:grid-cols-2 lg:place-self-center gap-8"
    >
      <div className="flex flex-col justify-center gap-8 lg:max-w-lg mx-auto">
        <h2 className="title text-center lg:text-left">Visita Nexpa</h2>
        <p>
          Barra de Nexpa es una localidad ubicada en la costa del Pacífico en
          Michoacán. Si bien es conocida por ser un paraíso surfero, también en
          los alrededores de la ola encuentras aguas tranquilas para practicar
          buceo, natación y pesca.
        </p>
        <p>
          En Barra de Nexpa encuentras un hermoso y amplio paisaje para
          disfrutar tanto del deporte y la adrenalina, como para relajarse en
          sus extensas playas, en donde puedes hacer largas caminatas, o fogatas
          al atardecer.
        </p>
        <p>
          En Barra de Nexpa tienes todo lo que necesitas. A 100 mts de nuestras
          cabañas encuentras nuestra tienda de Abarrotes, y un típico comedor
          para disfrutar de una riquísima gastronomía local.
        </p>
        <p>Te esperamos!</p>

        <div class="overflow-x-scroll snap-x flex gap-2 ">
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Nexpa.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Pesca.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Surf.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Cartel.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Tacos.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Nexpa/Cabañas.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
        </div>
        <div className="flex items-start">
          <AnchorLink to="/#cabins">
            <button className="hidden lg:block button-secondary max-w-xs lg:mx-0 lg:mr-4 lg:my-4">
              Conoce nuestras Cabañas
            </button>
          </AnchorLink>
          <a href="https://wa.me/527531154050" className="mx-auto">
            <button className="button-primary max-w-xs mx-auto lg:mx-0 lg:my-4">
              Hacenos tu consulta
            </button>
          </a>
        </div>
      </div>
      <div className="hidden lg:flex items-start">
        <StaticImage
          src="../../images/Nexpa/NexpaAll.png"
          placeholder="tracedSVG"
          alt="image of Nexpa"
          className="block border scale-75"
        />
      </div>
    </section>
  )
}

export default About
