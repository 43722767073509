import React, { useState } from "react"
import { Link } from "gatsby"
import {
  CardCabinAtardecer,
  CardCabinGaviota,
  CardCabinPerla,
  CardCamp,
  CardClima,
  CardHabitaciones,
} from "../Cards"

const Cabins = ({ display = "flex" }) => {
  return (
    <div id="cabins" className="h-full lg:min-h-screen py-4 lg:pt-0">
      <h2 className="title lg:subtitle text-center pt-8">Elegi tu cabaña</h2>
      <p className="paragraph text-center py-4  ">
        Elegí la cabaña que mejor se adapte a tus necesidades.
      </p>
      <Link to="/cabin/choose">
        <button
          className={` button-secondary lg:hidden max-w-xs w-full mx-auto mb-4 mt-2`}
        >
          Ver Cabañas
        </button>
      </Link>
      <div
        className={`${display} lg:flex items-center justify-center flex-wrap gap-8 lg:gap-16 lg:max-w-6xl mx-auto py-8 lg:pb-16 px-2`}
      >
        <CardCabinGaviota />
        <CardCabinPerla />
        <CardCabinAtardecer />
        <CardClima />
        <CardHabitaciones />
        <CardCamp />
      </div>
    </div>
  )
}

export default Cabins
