import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Location = () => {
  return (
    <div
      id="location"
      className="min-h-screen flex flex-col items-center lg:flex-row lg:min-h-screen mx-auto gap-8 lg:gap-16 lg:max-w-7xl px-8 pt-16"
    >
      <div className="lg:w-1/2 text-center space-y-8 max-w-lg">
        <h2 className="title lg:subtitle">Cómo llegar</h2>
        <p>
          {" "}
          Si vienes por tierra, puedes tomar la ciudad de Lázaro Cárdenas como
          tu punto de partida. Allí encuentras autobuses hacia Manzanillo que
          luego de una hora de recorrido, llegan hasta Nexpa. Estamos ubicados
          en el KM 55 de la carretera México 200.
        </p>

        <p>
          Si vienes por aire, el aeropuerto más cercano es el de la ciudad de
          Zihuatanejo, en Guerrero. Una vez allí puedes encontrar autobusses a
          Nexpa con un recorrido que toma aproximadamente 2 horas.{" "}
        </p>
        <button className="button-primary mx-auto ">
          <a href="https://wa.me/527531154050" className="px-2">
            Contactanos
          </a>
        </button>
      </div>
      <div className="lg:w-1/2 mx-auto xl:ml-auto px-2">
        <a href="https://www.google.com/maps/dir//google+max+nexpa+cabanas+martha/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x84311f79f9987afd:0x7512d71252649df5?sa=X&ved=2ahUKEwjb-br44Jv2AhUOlmoFHbo-ASUQ9Rd6BAguEAM">
          <StaticImage
            width={560}
            src="../../images/location.png"
            alt="location Nexpa"
            placeholder="tracedSVG"
            className="w-full rounded-lg shadow-lg"
          />
        </a>
      </div>
    </div>
  )
}

export default Location
