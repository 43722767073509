import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoEmbeded from "../components/modules/Video"
import About from "../components/modules/About"
import Services from "../components/modules/Services"
import Cabins from "../components/modules/Cabins"
import Enjoy from "../components/modules/Enjoy"
import Location from "../components/modules/Location"
import Forecast from "../components/modules/Forecast"

const IndexPage = () => (
  <>
    <div className="xl:h-screen flex justify-center">
      <div className="hidden lg:block">
        <StaticImage
          src="../images/DesktopHome.png"
          layout="constrained"
          placeholder="tracedSVG"
          alt="Image of Nexpa"
          className="h-screen"
        />
      </div>
    </div>

    <Layout>
      <Seo title="Bienvenidos" />
      <div className="block lg:hidden">
        <StaticImage
          src="../images/MobileHome.png"
          layout="constrained"
          placeholder="tracedSVG"
          alt="image of Nexpa"
        />
      </div>
      <VideoEmbeded />
      <About />
      <Services />
      <Cabins display="hidden" />
      <Enjoy />
      <Location />
      <Forecast />
    </Layout>
  </>
)

export default IndexPage
