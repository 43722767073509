import React from "react"
import {
  ForecastWidgetMobile,
  ForecastWidgetDesktop,
} from "../../atoms/ForecastWidget"

const Forecast = () => {
  return (
    <div
      id="forecast"
      className="min-h-screen flex px-8 flex-col items-center justify-center py-16"
    >
      <h2 className="title lg:subtitle">¿Vienes a Surfear?</h2>
      <p className="paragraph text-center py-4">
        Te esperamos en Cabañas Martha con todo lo que necesitas para hacer que
        tu estadía se sienta mejor que estar <br />
        en casa, mientras disfrutas de las mejores olas de la Costa Michoacana.
      </p>
      <div className="scale-50 lg:hidden">
        <ForecastWidgetMobile />
      </div>
      <div className="hidden lg:block">
        <ForecastWidgetDesktop />
      </div>
    </div>
  )
}

export default Forecast
