import React from "react"
import {
  CardServicesSurf,
  CardServicesParking,
  CardServicesPet,
  CardServicesPool,
  CardServicesWifi,
} from "../Cards"

const Services = () => {
  return (
    <div
      id="services"
      className="max-w-5xl flex flex-col items-center mx-auto py-8 lg:h-screen"
    >
      <h2 className="title lg:subtitle">Nuestros Servicios</h2>
      <p className="paragraph max-w-xs lg:max-w-full py-4 lg:py-0 lg:pb-16 text-center">
        En Cabañas Martha tenemos todo lo que necesitas para pasar unos días
        inolvidables.
      </p>
      <div className="grid place-content-center lg:grid-cols-3 gap-8 lg:gap-16">
        <CardServicesSurf />
        <CardServicesPool />
        <CardServicesParking />
      </div>
      <div className="grid place-content-center lg:grid-cols-2 gap-8 pt-8 lg:gap-16 lg:py-16">
        <CardServicesWifi />
        <CardServicesPet />
      </div>
    </div>
  )
}

export default Services
