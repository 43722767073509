import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Enjoy = () => {
  return (
    <section
      id="enjoy"
      className="max-w-7xl lg:mx-auto px-8 py-8 lg:py-0 flex flex-col lg:grid lg:grid-cols-2 lg:place-self-center gap-8 pt-24"
    >
      <div className="flex flex-col justify-center gap-8 lg:max-w-lg mx-auto">
        <h2 className="subtitle leading-tight xl:whitespace-nowrap">
          Disfrutá de la Costa <br /> Michoacana
        </h2>

        <p>
          {" "}
          Desde Nexpa puedes ir a conocer y disfrutar de las mejores atracciones
          de la costa Michoacana, como Playa Pichiliguillo, un imponente paisaje
          de aguas transparentes y arenas blancas.
        </p>

        <p>
          A una hora de Nexpa y por la Carretera Federal 200, encuentras La
          Llorona. Una playa tranquila, ideal para ir en familia y comer unos
          ricos camarones en sus costas.
        </p>

        <p>
          En las cercanías a Nexpa encuentras El Atrancón, unas pozas de agua
          azul, en el municipio de Aquila, a tan solo 20 minutos de nuestras
          cabañas.{" "}
        </p>

        <p>
          En la Costa Michoacana también puedes disfrutar de riquísimos
          manjares, como los típicos tacos, quesadillas, guisados y también
          camarones y pescados frescos.
        </p>

        <p>Te esperamos para conocer más de la Costa Michoacana!</p>

        <div class="overflow-x-scroll snap-x flex gap-4 ">
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Disfruta/LaLlorona.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Disfruta/Pichinguillo.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Disfruta/Disfruta.png"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
          <div class="snap-center flex-shrink-0 lg:hidden">
            <StaticImage
              height={248}
              src="../../images/Disfruta/Atrancon.jpg"
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block"
            />
          </div>
        </div>
        <div className="flex items-start">
          <a
            href="https://wa.me/527531154050"
            className="mx-auto lg:mx-0 lg:mr-auto"
          >
            <button className="button-primary max-w-xs mx-auto lg:my-4">
              Hacenos tu consulta
            </button>
          </a>
        </div>
      </div>
      <div className="hidden lg:block ">
        <StaticImage
          src="../../images/Disfruta/Disfruta2.png"
          placeholder="tracedSVG"
          alt="image of Nexpa"
          className="block border scale-75"
        />
      </div>
    </section>
  )
}

export default Enjoy
