import React from "react"

export const ForecastWidgetDesktop = () => {
  return (
    <>
      <link
        href="//www.surf-forecast.com/stylesheets/widget.css"
        media="screen"
        rel="stylesheet"
        type="text/css"
      />
      <div class="wf-width-cont surf-fc-widget">
        <div class="widget-container">
          <div class="external-cont">
            <iframe
              class="surf-fc-i"
              allowtransparency="true"
              src="//www.surf-forecast.com/breaks/Rio-Nexpa/forecasts/widget/a"
              scrolling="no"
              frameborder="0"
              marginwidth="0"
              marginheight="0"
            ></iframe>
            <div class="footer">
              <a class="logo" href="//www.surf-forecast.com/">
                <img
                  src="//www.surf-forecast.com/images/widget.png"
                  alt="Widget"
                  width="1"
                  height="1"
                />
              </a>
              <div class="about" id="cmt">
                View detailed surf forecast for{" "}
                <a href="//www.surf-forecast.com/breaks/Rio-Nexpa">Rio Nexpa</a>
                . Visit{" "}
                <a href="//www.surf-forecast.com/breaks/Rio-Nexpa">
                  surf-forecast.com
                </a>{" "}
                for more details, long range forecasts, surf reports, swell and
                weather maps.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const ForecastWidgetMobile = () => {
  return (
    <>
      <link
        href="//www.surf-forecast.com/stylesheets/widget.css"
        media="screen"
        rel="stylesheet"
        type="text/css"
      />
      <div class="wf-width-cont surf-fc-widget">
        <div class="widget-container">
          <div class="external-cont">
            <iframe
              class="surf-fc-i"
              allowtransparency="true"
              src="//www.surf-forecast.com/breaks/Rio-Nexpa/forecasts/widget/a"
              scrolling="no"
              frameborder="0"
              marginwidth="0"
              marginheight="0"
            ></iframe>
            <div class="footer">
              <a class="logo" href="//www.surf-forecast.com/">
                <img
                  src="//www.surf-forecast.com/images/widget.png"
                  alt="Widget"
                  width="1"
                  height="1"
                />
              </a>
              <div class="about" id="cmt">
                More{" "}
                <a href="//www.surf-forecast.com/breaks/Rio-Nexpa">
                  Detailed Surf Conditions &amp; Webcams for&nbsp;Rio Nexpa
                </a>{" "}
                <nobr>
                  at&nbsp;
                  <a href="//www.surf-forecast.com/">surf-forecast.com</a>
                </nobr>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
