import React from "react"

const VideoEmbeded = () => {
  return (
    <>
      <div
        id="video"
        className="lg:hidden flex items-center justify-center py-16 px-4"
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/hzbh4JUlW3A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="shadow-md rounded-lg"
        ></iframe>
      </div>
      <div className="hidden lg:flex h-screen items-center justify-center px-2">
        <iframe
          width="860"
          height="615"
          src="https://www.youtube.com/embed/hzbh4JUlW3A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="shadow-md max-w-screen lg:rounded-lg"
        ></iframe>
      </div>
    </>
  )
}

export default VideoEmbeded
